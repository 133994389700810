<template>
    <div>
      <!-- <a id="downloadAnchorElem"></a> -->

        <v-progress-linear
        indeterminate
        color="primary"
        height="10"
        top
        :active="loaded && !meccaHotelResolved && !medinaHotelResolved"
        ></v-progress-linear>
        <v-snackbar id="snackbar" v-model="snackbar" :color="color" top :timeout="5000">
          <v-row>
            {{ text }}
            <v-spacer></v-spacer>
            <v-btn
              dark
              icon
              @click="snackbar = false"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-snackbar>
        <v-overlay :value="showOverlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container v-if="!loaded && !flightAvailable">
           <v-row justify='center' class="pt-5 mt-5"><p class="display-1 text-center primary--text">{{$t('book.noFlightMsg')}}</p></v-row>
        </v-container>
        <v-container fluid v-else>
            <v-stepper v-if="flight !== null" class="elevation-0 my-5" v-model="el">
              <v-stepper-header class="elevation-0">
                <v-stepper-step
                :complete="el > 1"
                :editable="el === 2"
                :step="1"
                color="primary"
                >
                  {{$t('book.travellers')}}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                :complete="el > 1"
                :step="2"
                color="primary"
                >
                  {{$t('book.payment')}}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                :complete="el === 3"
                :step="3"
                color="primary"
                >
                  {{$t('book.confirmation')}}
                </v-stepper-step>

              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content :step="1">
                  <v-row class="px-1">
                    <v-col cols="12" md="8" lg="9">
                      <v-form v-model="valid1" ref="form1">
                        <v-card outlined class="pa-2 mb-5">
                          <p class="body-1 font-weight-bold secondary--text">{{$t('book.contact')}}</p>
                          <v-row>
                            <v-col cols="12" sm="4" md="3">
                              <v-text-field
                              outlined
                              v-model="name"
                              :rules="[v => !!v || 'Item is required', v => /^[a-zA-Z\s]*$/.test(v) || 'Name Must be letters only']"
                              :label="$t('book.fullName')"
                              required
                              color="blue"
                              id="passenger-fullname"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <phoneInput :phone="phone.nationalNumber" @update="assignPhone" />
                            </v-col>
                            <v-col cols="12" sm="4" md="5">
                              <v-text-field
                              outlined
                              v-model="email"
                              :rules="emailRules"
                              :label="$t('book.email')"
                              required
                              color="blue"
                              id="passenger-email"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card>

                        <v-card v-for="(room, i) in rooms" :key="i">
                          <v-card-title>{{$t('book.room', {count: i + 1})}}</v-card-title>
                          <div v-for="(adult, n) in room.adults" :key="'adult' + n" outlined class="mb-5">
                            <v-row class="pa-2">
                              <v-col cols="12">
                                <p class="body-1 secondary--text">
                                  {{$t('book.passenger', {count: n + 1})}},
                                  {{$t('book.adult')}}
                                </p>
                              </v-col>
                              <v-col cols="12" md="4" lg="4">
                                <v-select
                                v-model="adult.passengerGender"
                                :items="['Male', 'Female']"
                                :label="$t('book.gender')"
                                outlined
                                prepend-inner-icon="mdi-gender-male-female"
                                required
                                class=""
                                height="56px"
                                color="blue"
                                id="passenger-gender"
                                >
                                  <template v-slot:selection="{ item }">
                                    <span class="primary--text caption">{{item}}</span>
                                  </template>
                                </v-select>
                              </v-col>
                              <!-- <v-col cols="12" sm="6" md="6" lg="2" v-if="adultsNum >= n">
                                <v-combobox
                                v-model="adult.passengerTitle"
                                :items="['Mr', 'Mrs', 'Ms', 'Miss']"
                                :search-input.sync="search[n-1]"
                                hide-selected
                                hint="Add title and press enter to append it"
                                :label="$t('book.title')"
                                outlined
                                prepend-inner-icon="mdi-card-account-details"
                                class="pa-0"
                                height="56px"
                                color="blue"
                                id="passenger-title-combobox"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-combobox>
                              </v-col> -->
                              <!-- <v-col cols="12" sm="8" md="12" lg="8"> -->
                                <!-- <v-row class="my-0 py-0"> -->
                                  <v-col cols="12" md="4" class="">
                                    <v-text-field
                                    outlined
                                    prepend-inner-icon="mdi-account"
                                    v-model="adult.passengerFirstName"
                                    :rules="nameRules"
                                    :label="$t('book.firstName')"
                                    required
                                    color="blue"
                                    :id="`passenger-${n}-firstName`"
                                    ></v-text-field>
                                  </v-col>
                                  <!-- <v-col cols="12" sm="4" class="py-0">
                                    <v-text-field
                                    outlined
                                    prepend-inner-icon="mdi-account"
                                    v-model="passengerMiddleName[n-1]"
                                    :label="$t('book.middleName')"
                                    color="blue"
                                    :id="`passenger-${n}-middleName`"
                                    :rules="middleNameRules"
                                    ></v-text-field>
                                  </v-col> -->
                                  <v-col cols="12" md="4" class="">
                                    <v-text-field
                                    outlined
                                    v-model="adult.passengerLastName"
                                    prepend-inner-icon="mdi-account"
                                    :rules="nameRules"
                                    :label="$t('book.lastName')"
                                    required
                                    color="blue"
                                    :id="`passenger-${n}-lastName`"
                                    ></v-text-field>
                                  </v-col>
                                <!-- </v-row> -->
                              <!-- </v-col> -->
                              <v-col cols="12" sm="4" md="6" lg="3">
                                <date-picker
                                v-model="adult.date_of_birth"
                                clearable
                                editable
                                valueType="YYYY-MM-DD"
                                :placeholder="$t('book.bookingData.birth')"
                                :default-value="adultsMaxDate"
                                style="width: 100%; height: 56px;"
                                :disabled-date="adultsBirthDateRange"
                                required
                                format="M/D/YYYY"
                                hint="MM/DD/YYYY"
                                append-to-body
                                :input-attr="{id: 'birthDateId'+n}"
                                @focus="changeInputColor('birthDateId'+n, '#2196F3')"
                                @blur="changeInputColor('birthDateId'+n, '#9e9e9e')"
                                @input-error="changeInputColor('birthDateId'+n, 'red')"
                                :id="`passenger-${n}-birthday`"
                                >
                                </date-picker>
                                <span class="caption grey--text">Hint: MM/DD/YYYY</span>
                              </v-col>
                              <v-col cols="12" sm="4" md="6" lg="3">
                                <v-text-field
                                v-model="adult.passport_number"
                                :label="flight.isDomesticFlight ? $t('book.passportNumberOptional') : $t('book.passportNumber')"
                                prepend-inner-icon="mdi-passport"
                                outlined
                                :rules="passRules"
                                :hint="flight.isDomesticFlight ? 'Optional Field' : ''"
                                persistent-hint
                                color="blue"
                                :id="`passenger-${n}-passport-num`"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" md="6" lg="3">
                                <date-picker
                                v-model="adult.passport_expire_date"
                                clearable
                                editable
                                valueType="YYYY-MM-DD"
                                :placeholder="flight.isDomesticFlight ? $t('book.passportExpiryOptional') : $t('book.passportExpiry')"
                                :default-value="passportMinExpireDate"
                                style="width: 100%; height: 56px;"
                                :disabled-date="minExpireDateRange"
                                format="M/D/YYYY"
                                hint="MM/DD/YYYY'"
                                append-to-body
                                :input-attr="{id: 'passportExpiry'+n}"
                                @focus="changeInputColor('passportExpiry'+n, '#2196F3')"
                                @blur="changeInputColor('passportExpiry'+n, '#9e9e9e')"
                                @input-error="changeInputColor('passportExpiry'+n, 'red')"
                                :id="`passenger-${n}-passportExpiry`"
                                >
                                </date-picker>
                                <span class="caption grey--text">{{flight.isDomesticFlight ? 'Hint: Optional Field MM/DD/YYYY' : 'Hint: MM/DD/YYYY}'}}</span>
                              </v-col>
                              <v-col cols="12" sm="4" md="6" lg="3">
                                  <v-autocomplete
                                  v-model="adult.passport_issue_country"
                                  :items="countries"
                                  item-text="name"
                                  item-value="code"
                                  :placeholder="flight.isDomesticFlight ? $t('book.bookingData.issueOptional') :  $t('book.bookingData.issue')"
                                  :hint="flight.isDomesticFlight ? 'Optional Field' : ''"
                                  persistent-hint
                                  name="issue-country-for-passports"
                                  required
                                  outlined
                                  prepend-inner-icon="mdi-flag"
                                  hide-no-data
                                  color="blue"
                                  autocomplete="off"
                                  :menu-props="{ auto: true, maxWidth: 200, overflowY: true }"
                                  :id="`passenger-${n}-passport-issue-country`"
                                  ></v-autocomplete>
                              </v-col>

                            </v-row>
                            <v-divider></v-divider>
                            <!-- <v-card-actions>
                              <v-expansion-panels flat>
                                <v-expansion-panel>
                                  <v-expansion-panel-header class="blue--text">
                                    {{$t('book.frequent')}}
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-selectseatSelectType
                                        v-model="tsaRedress[n-1]"
                                        :items="['window seat', 'aisle row', 'exist row', 'extra leg room']"
                                        :label="$t('book.special.seatType')"
                                        outlined
                                        prepend-inner-icon="mdi-seat-passenger"
                                        class="pa-0"
                                        height="56px"
                                        color="blue"
                                        id="passenger-seat"
                                        >
                                          <template v-slot:selection="{ item }">
                                            <span class="primary--text caption text-capitalize">{{item}}</span>
                                          </template>
                                        </v-select>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                        v-model="frequentFlyer [n-1]"
                                        label="TSA Redress #"
                                        outlined
                                        color="blue"
                                        :id="`passenger-${n}-redress-num`"
                                        >
                                          <template v-slot:append>
                                            <v-tooltip
                                            bottom
                                            color="primary white--text"
                                            >
                                              <template v-slot:activator="{ on }">
                                                <v-icon v-on="on">
                                                  mdi-information-outline
                                                </v-icon>
                                              </template>
                                              This is a unique number
                                              the Department of Homeland Security(DHS)
                                              assigns to a passenger
                                              to promote resolution with
                                              previous watch list alerts.
                                            </v-tooltip>
                                          </template>
                                        </v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                        v-model="passengerKnownNumber"
                                        label="Known Traveller Number"
                                        outlined
                                        color="blue"
                                        :id="`passenger-${n}-known-traveller-num`"
                                        >
                                        </v-text-field>
                                      </v-col>
                                    </v-row>
                                    <p>{{$t('book.special.title')}}</p>
                                    <v-row>
                                      <v-col cols="12" sm="6" v-if="!flight.isDomesticFlight">
                                        <v-select
                                        v-model="passengerMeal"
                                        :items="['Inf/Baby Food',
                                        'Bland Meal',
                                        'Child Meal',
                                        'Diabetic Meal',
                                        'Gluten Free',
                                        'High Fiber Meal',
                                        'Hindu',
                                        'Kosher',
                                        'Low Calorie',
                                        'Low Carbohydrate',
                                        'Low Choles./Fat',
                                        'Low Protein',
                                        'Low Sodium',
                                        'Muslim Meal',
                                        'Nonlactose',
                                        'No Salt Meal',
                                        'Oriental',
                                        'Peanut Free Meal',
                                        'Low Purine Meal',
                                        'Raw Vegetarian',
                                        'Seafood Meal',
                                        'Spec Request',
                                        'Vegetarian',
                                        'Veg/Lacto-ovo']"
                                        :label="$t('book.special.specialMeal')"
                                        outlined
                                        ></v-select>
                                      </v-col>
                                      <v-col cols="12" sm="6">
                                        <v-select
                                        v-model="passengerSpecialService"
                                        outlined
                                        :label="$t('book.special.specialService')"
                                        :items="['Blind Passenger',
                                        'Bassinet for Infant',
                                        'Deaf Passenger',
                                        'Stretcher Assistance',
                                        'Wheelchair - Cannot walk up stairs nor to the seat. Must be carried.',
                                        'Wheelchair - Can walk up stairs and to the seat',
                                        'Wheelchair - Cannot walk up stairs but is able to walk to the seat',]"
                                        >
                                        </v-select>
                                      </v-col>
                                    </v-row>
                                    <p>{{$t('book.special.frequentFlyer')}}</p>
                                    <v-row>
                                      <v-col
                                      v-for="(item, x) in flight.marketingAirlines" :key="x"
                                      cols="12"
                                      sm="6"
                                      lg="4"
                                      >
                                        <v-text-field
                                        v-model="frequentFlyersNumbers[n-1][x].number"
                                        :label="$t('book.special.frequentFlyer')"
                                        outlined
                                        color="blue"
                                        :id="`passenger-${n}-ff-num`"
                                        >
                                          <template v-slot:prepend-inner>
                                            <v-tooltip
                                            bottom
                                            color="primary white--text"
                                            >
                                              <template v-slot:activator="{ on }">
                                                <img v-on="on" :src="item.airlineLogo" style="max-width: 30px; max-height: 30px;" :alt="item.airlineName">
                                              </template>
                                              {{item.airlineName}}
                                            </v-tooltip>
                                          </template>
                                        </v-text-field>
                                      </v-col>
                                    </v-row>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </v-card-actions> -->
                          </div>

                          <div v-for="(child, n) in room.children" :key="'child'+n" outlined class="mb-5">
                            <v-row class="pa-2">
                              <v-col cols="12">
                                <p class="body-1 secondary--text">
                                  {{$t('book.passenger', {count: n + 1})}},
                                  {{$t('book.child')}}
                                </p>
                              </v-col>
                              <v-col cols="12" md="4" lg="4">
                                <v-select
                                v-model="child.passengerGender"
                                :items="['Male', 'Female']"
                                :label="$t('book.gender')"
                                outlined
                                prepend-inner-icon="mdi-gender-male-female"
                                required
                                class=""
                                height="56px"
                                color="blue"
                                id="passenger-gender"
                                >
                                  <template v-slot:selection="{ item }">
                                    <span class="primary--text caption">{{item}}</span>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col cols="12" md="4" class="">
                                <v-text-field
                                outlined
                                prepend-inner-icon="mdi-account"
                                v-model="child.passengerFirstName"
                                :rules="nameRules"
                                :label="$t('book.firstName')"
                                required
                                color="blue"
                                :id="`passenger-${n}-firstName`"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4" class="">
                                <v-text-field
                                outlined
                                v-model="child.passengerLastName"
                                prepend-inner-icon="mdi-account"
                                :rules="nameRules"
                                :label="$t('book.lastName')"
                                required
                                color="blue"
                                :id="`passenger-${n}-lastName`"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" md="6" lg="3">
                                <date-picker
                                v-model="child.date_of_birth"
                                clearable
                                editable
                                valueType="YYYY-MM-DD"
                                :placeholder="$t('book.bookingData.birth')"
                                :default-value="childrenMaxDate"
                                style="width: 100%; height: 56px;"
                                :disabled-date="childrenBirthDateRange"
                                required
                                format="M/D/YYYY"
                                hint="MM/DD/YYYY"
                                append-to-body
                                :input-attr="{id: 'birthDateId'+n}"
                                @focus="changeInputColor('birthDateId'+n, '#2196F3')"
                                @blur="changeInputColor('birthDateId'+n, '#9e9e9e')"
                                @input-error="changeInputColor('birthDateId'+n, 'red')"
                                :id="`passenger-${n}-birthday`"
                                >
                                </date-picker>
                                <span class="caption grey--text">Hint: MM/DD/YYYY</span>
                              </v-col>
                              <v-col cols="12" sm="4" md="6" lg="3">
                                <v-text-field
                                v-model="child.passport_number"
                                :label="flight.isDomesticFlight ? $t('book.passportNumberOptional') : $t('book.passportNumber')"
                                prepend-inner-icon="mdi-passport"
                                outlined
                                :rules="passRules"
                                :hint="flight.isDomesticFlight ? 'Optional Field' : ''"
                                persistent-hint
                                color="blue"
                                :id="`passenger-${n}-passport-num`"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" md="6" lg="3">
                                <date-picker
                                v-model="child.passport_expire_date"
                                clearable
                                editable
                                valueType="YYYY-MM-DD"
                                :placeholder="flight.isDomesticFlight ? $t('book.passportExpiryOptional') : $t('book.passportExpiry')"
                                :default-value="passportMinExpireDate"
                                style="width: 100%; height: 56px;"
                                :disabled-date="minExpireDateRange"
                                format="M/D/YYYY"
                                hint="MM/DD/YYYY'"
                                append-to-body
                                :input-attr="{id: 'passportExpiry'+n}"
                                @focus="changeInputColor('passportExpiry'+n, '#2196F3')"
                                @blur="changeInputColor('passportExpiry'+n, '#9e9e9e')"
                                @input-error="changeInputColor('passportExpiry'+n, 'red')"
                                :id="`passenger-${n}-passportExpiry`"
                                >
                                </date-picker>
                                <span class="caption grey--text">{{flight.isDomesticFlight ? 'Hint: Optional Field MM/DD/YYYY' : 'Hint: MM/DD/YYYY}'}}</span>
                              </v-col>
                              <v-col cols="12" sm="4" md="6" lg="3">
                                  <v-autocomplete
                                  v-model="child.passport_issue_country"
                                  :items="countries"
                                  item-text="name"
                                  item-value="code"
                                  :placeholder="flight.isDomesticFlight ? $t('book.bookingData.issueOptional') :  $t('book.bookingData.issue')"
                                  :hint="flight.isDomesticFlight ? 'Optional Field' : ''"
                                  persistent-hint
                                  name="issue-country-for-passports"
                                  required
                                  outlined
                                  prepend-inner-icon="mdi-flag"
                                  hide-no-data
                                  color="blue"
                                  autocomplete="off"
                                  :menu-props="{ auto: true, maxWidth: 200, overflowY: true }"
                                  :id="`passenger-${n}-passport-issue-country`"
                                  ></v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-divider></v-divider>
                           </div>

                           <div v-for="(infant, n) in room.infants" :key="'inf'+n" outlined class="mb-5">
                            <v-row class="pa-2">
                              <v-col cols="12">
                                <p class="body-1 secondary--text">
                                  {{$t('book.passenger', {count: n + 1})}},
                                  {{$t('book.infant')}}
                                </p>
                              </v-col>
                              <v-col cols="12" md="4" lg="4">
                                <v-select
                                v-model="infant.passengerGender"
                                :items="['Male', 'Female']"
                                :label="$t('book.gender')"
                                outlined
                                prepend-inner-icon="mdi-gender-male-female"
                                required
                                class=""
                                height="56px"
                                color="blue"
                                id="passenger-gender"
                                >
                                  <template v-slot:selection="{ item }">
                                    <span class="primary--text caption">{{item}}</span>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col cols="12" md="4" class="">
                                <v-text-field
                                outlined
                                prepend-inner-icon="mdi-account"
                                v-model="infant.passengerFirstName"
                                :rules="nameRules"
                                :label="$t('book.firstName')"
                                required
                                color="blue"
                                :id="`passenger-${n}-firstName`"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4" class="">
                                <v-text-field
                                outlined
                                v-model="infant.passengerLastName"
                                prepend-inner-icon="mdi-account"
                                :rules="nameRules"
                                :label="$t('book.lastName')"
                                required
                                color="blue"
                                :id="`passenger-${n}-lastName`"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" md="6" lg="3">
                                <date-picker
                                v-model="infant.date_of_birth"
                                clearable
                                editable
                                valueType="YYYY-MM-DD"
                                :placeholder="$t('book.bookingData.birth')"
                                :default-value="infantsMinDate"
                                style="width: 100%; height: 56px;"
                                :disabled-date="infantsBirthDateRange"
                                required
                                format="M/D/YYYY"
                                hint="MM/DD/YYYY"
                                append-to-body
                                :input-attr="{id: 'birthDateId'+n}"
                                @focus="changeInputColor('birthDateId'+n, '#2196F3')"
                                @blur="changeInputColor('birthDateId'+n, '#9e9e9e')"
                                @input-error="changeInputColor('birthDateId'+n, 'red')"
                                :id="`passenger-${n}-birthday`"
                                >
                                </date-picker>
                                <span class="caption grey--text">Hint: MM/DD/YYYY</span>
                              </v-col>
                              <v-col cols="12" sm="4" md="6" lg="3">
                                <v-text-field
                                v-model="infant.passport_number"
                                :label="flight.isDomesticFlight ? $t('book.passportNumberOptional') : $t('book.passportNumber')"
                                prepend-inner-icon="mdi-passport"
                                outlined
                                :rules="passRules"
                                :hint="flight.isDomesticFlight ? 'Optional Field' : ''"
                                persistent-hint
                                color="blue"
                                :id="`passenger-${n}-passport-num`"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" md="6" lg="3">
                                <date-picker
                                v-model="infant.passport_expire_date"
                                clearable
                                editable
                                valueType="YYYY-MM-DD"
                                :placeholder="flight.isDomesticFlight ? $t('book.passportExpiryOptional') : $t('book.passportExpiry')"
                                :default-value="passportMinExpireDate"
                                style="width: 100%; height: 56px;"
                                :disabled-date="minExpireDateRange"
                                format="M/D/YYYY"
                                hint="MM/DD/YYYY'"
                                append-to-body
                                :input-attr="{id: 'passportExpiry'+n}"
                                @focus="changeInputColor('passportExpiry'+n, '#2196F3')"
                                @blur="changeInputColor('passportExpiry'+n, '#9e9e9e')"
                                @input-error="changeInputColor('passportExpiry'+n, 'red')"
                                :id="`passenger-${n}-passportExpiry`"
                                >
                                </date-picker>
                                <span class="caption grey--text">{{flight.isDomesticFlight ? 'Hint: Optional Field MM/DD/YYYY' : 'Hint: MM/DD/YYYY}'}}</span>
                              </v-col>
                              <v-col cols="12" sm="4" md="6" lg="3">
                                  <v-autocomplete
                                  v-model="infant.passport_issue_country"
                                  :items="countries"
                                  item-text="name"
                                  item-value="code"
                                  :placeholder="flight.isDomesticFlight ? $t('book.bookingData.issueOptional') :  $t('book.bookingData.issue')"
                                  :hint="flight.isDomesticFlight ? 'Optional Field' : ''"
                                  persistent-hint
                                  name="issue-country-for-passports"
                                  required
                                  outlined
                                  prepend-inner-icon="mdi-flag"
                                  hide-no-data
                                  color="blue"
                                  autocomplete="off"
                                  :menu-props="{ auto: true, maxWidth: 200, overflowY: true }"
                                  :id="`passenger-${n}-passport-issue-country`"
                                  ></v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-divider></v-divider>
                           </div>
                        </v-card>
                      </v-form>
                    </v-col>
                    <v-col cols="12" md="4" lg="3">
                      <v-card outlined class="elevation-0 mb-5">
                        <v-card-title class="primary--text font-weight-bold text-no-wrap py-0 pt-2">{{$t('book.total')}}</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                          <div class="d-flex justify-space-between flex-wrap">
                            <p>Mecca Hotel Price</p>
                            {{ meccaHotelPrice | changeCurrency }}
                          </div>
                          <div class="d-flex justify-space-between flex-wrap">
                            <p>Medina Hotel Price</p>
                            {{ medinaHotelPrice | changeCurrency }}
                          </div>
                          <!-- <div class="d-flex justify-space-between flex-wrap">
                            <p>Flight Price</p>
                            {{ flight.pricingInfo.TotalFare | changeCurrency }}
                          </div> -->
                          <div class="d-flex justify-space-between flex-wrap">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" >Flight, Visa, and transportations <v-icon small right>mdi-information-slab-circle</v-icon></span>
                              </template>
                              <span>This fee is for flight, visa, transportations and any other service costs.</span>
                            </v-tooltip>
                            {{ extraCost + flight.pricingInfo.TotalFare | changeCurrency }}
                          </div>
                        </v-card-text>
                        <v-card-actions class="primary py-5">
                          <p class="text-h6 primary white--text font-weight-bold mb-0 text-right">{{$t('book.grand')}}</p>
                          <v-spacer></v-spacer>
                          <p class="text-h6 primary white--text font-weight-bold mb-0 text-right">{{Number(packageTotalPrice) | changeCurrency}}</p>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- <v-btn
                  disabled
                  color="primary white--text"
                  @click="nextClicked(1)"
                  class="py-8"
                  :style="$vuetify.breakpoint.lgAndUp ? 'width: 75%;' : ($vuetify.breakpoint.md ? 'width: 66%;' : 'width: 100%;')"
                  >
                    {{$t('book.continue')}}
                  </v-btn> -->

                  <v-btn
                  color="primary white--text"
                  @click="nextClicked(1)"
                  class="py-8"
                  :style="$vuetify.breakpoint.lgAndUp ? 'width: 75%;' : ($vuetify.breakpoint.md ? 'width: 66%;' : 'width: 100%;')"
                  >
                    {{$t('book.continue')}}
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content :step="2">
                  <v-row class="px-1">
                    <v-col cols="12" md="8" lg="9">
                      <!-- <bookinData type="flights" :items="travellersData" /> -->
                      <v-form v-model="valid2" ref="form2">
                        <address-card v-if="paymentType === 'credit'" />
                        <v-card outlined class="pa-5 mt-5">
                          <v-card-title>{{$t('book.payment')}}</v-card-title>
                          <v-row>
                          </v-row>
                          <credit @holder="setCardHolder" @card="setCardData" @expire="setCardExpiry" @cvv="setCVV" />
                        </v-card>
                        <v-row class="my-5" v-if="paymentType === 'credit' && $store.state.mode !== 'ats' && $store.state.mode !== 'atsDev'">
                          <v-btn text :color="termsIsRead ? 'success' : 'error'" large @click="termsDialog = true">
                            <v-checkbox id="booking-terms-checkbox" :color="termsIsRead ? 'success' : 'error'" readonly v-model="termsIsRead"></v-checkbox>
                            {{termsIsRead ? $t('book.termsRead') : $t('book.termsUnRead')}}
                          </v-btn>
                        </v-row>
                      </v-form>
                    </v-col>
                    <v-col cols="12" md="4" lg="3">
                      <v-card outlined class="elevation-0 mb-5">
                        <v-card-title class="primary--text font-weight-bold text-no-wrap py-0 pt-2">{{$t('book.total')}}</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                          <div class="d-flex justify-space-between flex-wrap">
                            <p>Mecca Hotel Price</p>
                            {{ meccaHotelPrice | changeCurrency }}
                          </div>
                          <div class="d-flex justify-space-between flex-wrap">
                            <p>Medina Hotel Price</p>
                            {{ medinaHotelPrice | changeCurrency }}
                          </div>
                          <!-- <div class="d-flex justify-space-between flex-wrap">
                            <p>Flight Price</p>
                            {{ flight.pricingInfo.TotalFare | changeCurrency }}
                          </div> -->
                          <div class="d-flex justify-space-between flex-wrap">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" >Flight, Visa, and transportations <v-icon small right>mdi-information-slab-circle</v-icon></span>
                              </template>
                              <span>This fee is for flight, visa, transportations and any other service costs.</span>
                            </v-tooltip>
                            {{ extraCost + flight.pricingInfo.TotalFare | changeCurrency }}
                          </div>
                        </v-card-text>
                        <v-card-actions class="primary py-5">
                          <p class="text-h6 primary white--text font-weight-bold mb-0 text-right">{{$t('book.grand')}}</p>
                          <v-spacer></v-spacer>
                          <p class="text-h6 primary white--text font-weight-bold mb-0 text-right">{{Number(packageTotalPrice) | changeCurrency}}</p>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-btn
                  :disabled="disbleBookingBtn"
                  color="primary white--text"
                  @click="nextClicked(2)"
                  class="py-8"
                  :style="$vuetify.breakpoint.lgAndUp ? 'width: 75%;' : ($vuetify.breakpoint.md ? 'width: 66%;' : 'width: 100%;')"
                  :loading="sendEmailLoad"
                  >
                    {{$t('book.book')}}
                  </v-btn>
                  <!-- <p class="caption">{{$t('book.currencyNote', {currency: defaultCurrency})}}</p> -->
                </v-stepper-content>
                <v-stepper-content :step="3">
                  <v-container>
                    <div style="text-align: center; background-color: white; padding: 20px 50px; margin: 20px auto; border: 1px solid #e8e8e8;">
                      <h2 class="blue--text">{{$t('book.confirm.thanksMsg')}} <span id="site-name">{{ applicationTitle }}</span></h2>
                      <hr class="my-2">
                      <!-- <h4 class="font-weight-bold my-2">{{$t('book.confirm.pnrMsg')}} <span class="orange--text">{{confirmData.bookingReference}}</span></h4> -->
                      <p style="color: red;">
                        {{$t('book.confirm.mailCheck')}}
                      </p>
                    </div>

                    <h2>Flight Details</h2>
                    <flightCard :flight="flight"></flightCard>

                    <h2>Mecca Stay</h2>
                    <v-card outlined class="pa-2">
                      <v-row>
                        <v-col cols="2">
                          <v-img class="rounded" :src="meccaHotel.Hotel.images[0]"></v-img>
                        </v-col>
                        <v-col cols="8">
                          <h3>{{ meccaHotel.Hotel.HotelName }}</h3>
                          <v-rating class="mt-2 mb-0 mr-3" dense :length="meccaHotel.Hotel.HotelStars" color="warning" readonly x-small  :value="parseInt(meccaHotel.Hotel.HotelStars)" full-icon="mdi-star"></v-rating>
                          <p>From : {{ new Date(meccaHotel.Hotel.checkInDate).toDateString() }} to: {{ new Date(meccaHotel.Hotel.CheckOutDate).toDateString() }}</p>
                        </v-col>
                        <v-col cols="2">
                          <h3>Total</h3>
                          <h3>{{ meccaHotelPrice | changeCurrency }}</h3>
                        </v-col>
                      </v-row>
                    </v-card>

                    <h2 v-if="medinaNights" class="mt-5">Medina Stay</h2>
                    <v-card outlined class="pa-2">
                      <v-row>
                        <v-col cols="2">
                          <v-img class="rounded" :src="medinaHotel.Hotel.images[0]"></v-img>
                        </v-col>
                        <v-col cols="8">
                          <h3>{{ medinaHotel.Hotel.HotelName }}</h3>
                          <v-rating class="mt-2 mb-0 mr-3" dense :length="medinaHotel.Hotel.HotelStars" color="warning" readonly x-small  :value="parseInt(medinaHotel.Hotel.HotelStars)" full-icon="mdi-star"></v-rating>
                          <p>From : {{ new Date(medinaHotel.Hotel.checkInDate).toDateString() }} to: {{ new Date(medinaHotel.Hotel.CheckOutDate).toDateString() }}</p>
                        </v-col>
                        <v-col cols="2">
                          <h3>Total</h3>
                          <h3>{{ medinaHotelPrice | changeCurrency }}</h3>
                        </v-col>
                      </v-row>
                    </v-card>

                    <div style="background-color: white; border: 1px solid #e8e8e8; margin-top: 20px">
                      <div style="background-color: #3565b0; color: white; font-size: 20px; padding: 10px;">{{$t('book.confirm.billing')}}</div>
                      <table style="width: 100%; padding: 10px;">
                        <!-- <tr>
                          <td style="text-align: left; font-size: 20px; font-weight: bold; padding: 10px 0;">Flight Price</td>
                          <td style="text-align: right; font-size: 20px; font-weight: bold; padding: 10px 0;">{{Number(flight.pricingInfo.TotalFare) | changeCurrency}}</td>
                        </tr> -->
                        <tr>
                          <td style="text-align: left; font-size: 20px; font-weight: bold; padding: 10px 0;">Mecca stay price</td>
                          <td style="text-align: right; font-size: 20px; font-weight: bold; padding: 10px 0;">{{Number(meccaHotelPrice) | changeCurrency}}</td>
                        </tr>
                        <tr v-if="medinaNights">
                          <td style="text-align: left; font-size: 20px; font-weight: bold; padding: 10px 0;">Medina stay price</td>
                          <td style="text-align: right; font-size: 20px; font-weight: bold; padding: 10px 0;">{{Number(medinaHotelPrice) | changeCurrency}}</td>
                        </tr>
                        <tr>
                          <td style="text-align: left; font-size: 20px; font-weight: bold; padding: 10px 0;">Flight & eXTRA Fees</td>
                          <td style="text-align: right; font-size: 20px; font-weight: bold; padding: 10px 0;">{{Number(extraCost) + Number(flight.pricingInfo.TotalFare) | changeCurrency}}</td>
                        </tr>
                        <tr>
                          <td style="text-align: left; font-size: 20px; font-weight: bold; padding: 10px 0;">{{$t('book.total')}}</td>
                          <td style="text-align: right; font-size: 20px; font-weight: bold; padding: 10px 0;">{{Number(packageTotalPrice) | changeCurrency}}</td>
                        </tr>
                      </table>
                    </div>
                    <a href="https://visa.visitsaudi.com/Login" target="_blank">
                      <v-btn
                      color="primary white--text"
                      block
                      class="my-5"
                      >
                        Visit Visa Site
                      </v-btn>
                    </a>
                    <v-btn
                      color="primary white--text"
                      block
                      @click="$router.push({ path: '/' })"
                      class="my-5"
                    >
                      {{$t('book.confirm.back')}}
                    </v-btn>
                  </v-container>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          <v-row v-else>
            <v-col cols="12">
              <p class="display-3 text-center primary--text">{{$t('book.noFlightMsg')}}</p>
            </v-col>
          </v-row>
          <v-dialog scrollable v-model="termsDialog" max-width="700">
            <v-card class="pa-5">
              <v-card-text style="height: 500px;">
                <terms />
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-checkbox color="primary" @change="termsIsRead ? termsDialog = false : ''" v-model="termsIsRead" label="I agree on terms & conditions"></v-checkbox>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog max-width="700" v-model="baggageDialog">
            <v-card>
                <div v-if="baggageRules.length > 0">
                  <div v-for="(item, i) in baggageRules" :key="i">
                    <v-card-title class="headline primary white--text font-weight-regular">
                      <span>
                        {{item.airlineName}}, <sub class="caption">{{item.airlineCode}} </sub>
                        <span>{{i === 0 || $store.state.flightType === 'multi' ? 'Departure' : 'Return'}}</span>
                        {{$t('flightSearch.card.baggageRules')}}
                      </span>
                    </v-card-title>
                    <a class="ma-3 blue--text" target="_blank" v-if="item.baggage_url" :href="item.baggage_url">{{$t('flightSearch.card.baggageRules')}}</a>
                    <v-card-text v-if="item.freePieces">
                        <p class="headline text-center secondary--text">{{$t('flightSearch.card.freePieces')}}</p>
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                      {{$t('flightSearch.card.numberOfPieces')}}
                                    </th>
                                    <th class="text-left">
                                      {{$t('flightSearch.card.weight')}}
                                    </th>
                                    <th class="text-left">
                                      {{$t('flightSearch.card.size')}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">{{ item.freePieces.numberOfPieces }}</td>
                                    <td class="text-left">{{ item.freePieces.weight }}</td>
                                    <td class="text-left">{{ item.freePieces.size }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-text v-if="item.additionalCost">
                        <v-divider class="mt-2 mb-5"></v-divider>
                        <p class="headline text-center secondary--text">{{$t('flightSearch.card.addCost')}}</p>
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                       {{$t('flightSearch.card.fees')}}
                                    </th>
                                    <th class="text-left">
                                       {{$t('flightSearch.card.weight')}}
                                    </th>
                                    <th class="text-left">
                                       {{$t('flightSearch.card.size')}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">$ {{ item.additionalCost.fees }}</td>
                                    <td class="text-left">{{ item.additionalCost.weight }}</td>
                                    <td class="text-left">{{ item.additionalCost.size }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                  </div>
                </div>
            </v-card>
          </v-dialog>
        </v-container>
    </div>
</template>

<script>
import flights from '@/requests/flights.js'
import hotels from '@/requests/hotels.js'
import packages from '@/requests/packages.js'
import { countries } from '@/countries.js'
import credit from '@/components/booking/credit'
import phoneInput from '@/components/booking/mobile'
import terms from '@/components/booking/terms'
import addressCard from '@/components/booking/addressInfo'
import flightCard from '@/components/flights/flightCard'
import { mapState } from 'vuex'

export default {
  components: {
    credit,
    phoneInput,
    terms,
    addressCard,
    flightCard
  },
  data () {
    return {
      applicationTitle: 'Adam travel Umrah Express',
      el: 1,
      showOverlay: false,
      paymentType: 'credit',
      nextLabel: 'Continue',
      valid1: true,
      valid2: true,
      loaded: true,
      snackbar: false,
      color: '',
      text: '',
      // flight: null,
      baggageRules: [],
      passengers: {},
      name: '',
      nameRules: [v => (!!v && v.length > 1) || 'Item is required at least 2 characters',
        v => /^[A-Za-z][A-Za-z]*$/.test(v) || 'Name Must be letters only with no spaces'
      ],
      middleNameRules: [
        v => (/^[A-Za-z][A-Za-z]*$/.test(v) || !v) || 'Name Must be letters only with no spaces'
      ],
      phone: '',
      phoneRules: [
        v => !!v || 'Phone is required',
        v => (!isNaN(v)) || 'Phone must be only numbers'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid'
      ],
      zipRules: [
        v => !!v || 'Zip code is required',
        v => (!isNaN(v)) || 'Zip code must be only numbers'
      ],
      zip: '',
      requiredRule: [
        v => !!v || 'Field is required'
      ],
      passRules: [
        v => !!v || 'Passport number is required',
        v => v.length <= 15 || 'Maximum number of characters is 15',
        v => v.length >= 5 || 'Minimum number of characters is 5'
      ],
      address: '',
      streetLine: '',
      city: '',
      state: '',
      countryISO: '',
      cardExpireDate: '',
      cardNum: '',
      cvv: '',
      cardCode: '',
      cardHolder: '',
      types: [
        { label: 'Adult', code: 'ADT' },
        { label: 'Child', code: 'CNN' },
        { label: 'Infant', code: 'INF' }
      ],
      baggageDialog: false,
      passengerType: [],
      passengerTitle: [],
      passengerGender: [],
      passengerSeatType: [],
      passengerRedress: [],
      passengerKnownNumber: [],
      passengerMeal: [],
      passengerSpecialService: [],
      search: [],
      passengerFirstName: [],
      passengerMiddleName: [],
      passengerLastName: [],
      dateOfBirth: [],
      passExpireDate: [],
      passExpireDateText: [],
      frequentFlyersNumbers: [],
      minExpireDate: '',
      passNum: [],
      issueCountry: [],
      currentPage: 0,
      travellersData: [],
      termsIsRead: false,
      termsDialog: false,
      lastFlightSegmentDate: '',
      passportExpiry: null,
      confirmData: {},
      flightAvailable: false,
      packageTotalPrice: 0,
      bookinRef: '',
      // meccaHotel: null,
      // medinaHotel: null,
      meccaHotelResolved: false,
      medinaHotelResolved: false,
      meccaHotelPrice: 0,
      medinaHotelPrice: 0,
      rooms: [],
      meccaHoldingRefernce: '',
      meccaHotelHeld: false,
      medinaHoldingRefernce: '',
      medinaHotelHeld: false,
      authorizeNetToken: null,
      hotelsBookingDetails: null,
      disbleBookingBtn: false,
      extraCost: 0,
      sendEmailLoad: false
    }
  },
  watch: {
    el (newVal) {
      if (newVal === 2) {
        document.getElementById('card-address').addEventListener('input', (e) => {
          this.$store.dispatch('setWrittenAddress', e.target.value)
        })
      }
    }
  },
  computed: {
    ...mapState(['flight', 'packageDetails', 'adults', 'oldChildren', 'children', 'infants', 'meccaHotel', 'medinaHotel', 'stayFromDate', 'stayToDate', 'currency', 'meccaNights',
      'medinaNights', 'meccaRoomsParams', 'medinaRoomsParams', 'roomsAndoccupancy']),
    countries () {
      return countries
    },
    adultsNum () {
      return Number(this.passengers.adults)
    },
    childrenNum () {
      return Number(this.passengers.children)
    },
    infantsNum () {
      return Number(this.passengers.infants)
    },
    passengersNum () {
      return Number(this.passengers.adults) + Number(this.passengers.children) + Number(this.passengers.infants)
    },
    adultsMaxDate () {
      const today = new Date(this.lastFlightSegmentDate)
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const max = today.setFullYear(year - 12)
      return max
    },
    childrenMaxDate () {
      const today = new Date(this.lastFlightSegmentDate)
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const max = today.setFullYear(year - 2)
      return max
    },
    childrenMinDate () {
      const today = new Date(this.lastFlightSegmentDate)
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const min = today.setFullYear(year - 12)
      return min
    },
    infantsMinDate () {
      const today = new Date(this.lastFlightSegmentDate)
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const min = today.setFullYear(year - 2)
      return min
    },
    passportMinExpireDate () {
      const lastFlightSegment = this.flight.flightSegments[this.flight.flightSegments.length - 1]
      const lastArrivalDate = lastFlightSegment.leg.arrivalDate
      const today = new Date(lastArrivalDate)
      today.setMonth(today.getMonth() + 6)
      return today
    },
    defaultCurrency () {
      return process.env.VUE_APP_DEFAULT_CURRENCY
    }
  },
  methods: {
    // setAddress (address, zip, streetLine, city, state, countryIso, addressObject) {
    //   this.address = address
    //   this.zip = zip
    //   this.streetLine = streetLine
    //   this.city = city
    //   this.state = state
    //   this.addressObject = addressObject
    //   this.countryISO = countryIso
    // },
    changeInputColor (id, color) {
      document.getElementById(id).style.borderColor = color
    },
    assignPhone (phone) {
      this.phone = phone
    },
    adultsBirthDateRange (date) {
      return date > new Date(this.adultsMaxDate) || date < new Date('1900-1-1')
    },
    childrenBirthDateRange (date) {
      return date > new Date(this.childrenMaxDate) || date < new Date(this.childrenMinDate)
    },
    infantsBirthDateRange (date) {
      const today = new Date()
      return date > today || date < new Date(this.infantsMinDate)
    },
    minExpireDateRange (date) {
      return date < new Date(this.passportMinExpireDate)
    },
    setCardData (number, type) {
      this.cardNum = number
      switch (type) {
        case 'visa':
          this.cardCode = 'VI'
          break
        case 'mastercard':
          this.cardCode = 'CA'
          break
        case 'american-express':
          this.cardCode = 'AX'
          break
        case 'discover':
          this.cardCode = 'DS'
          break
        case 'diners-club':
          this.cardCode = 'DN'
          break
        default:
          this.cardCode = type
          break
      }
    },
    setCardExpiry (date) {
      this.cardExpireDate = date
    },
    setCVV (cvv) {
      this.cvv = cvv
    },
    setCardHolder (name) {
      this.cardHolder = name
    },
    classType () {
      let type
      switch (this.passengers.classType) {
        case 'Y':
          type = 'Economy'
          break
        case 'C':
          type = 'Business'
          break
        case 'F':
          type = 'First'
          break
        case 'S':
          type = 'Premium Economy'
          break
        default:
          break
      }
      return type
    },
    // sendPaymentDataToAnet () {
    //   const authData = {}
    //   authData.clientKey = process.env.NODE_ENV === 'development' ? '5RwWyDRc9cD6Cm5K3dsK33zWuWz6qRz6V2bC3nz4q3RyTfLVWt5A4XS9RcK2w8AY' : '7e78jU6TUPp75pFd48aj85zE9cS6T98PYLS2w3eMmnFqvN67VKLMu45jL788FAk9'
    //   authData.apiLoginID = process.env.NODE_ENV === 'development' ? '4U9Ye5Hdq' : '979GuM8CrN'
    //   const cardData = {}
    //   cardData.cardNumber = this.cardNum
    //   cardData.month = `${this.cardExpireDate.split('-')[1]}`
    //   cardData.year = `${this.cardExpireDate.split('-')[0]}`
    //   cardData.cardCode = this.cvv
    //   cardData.cardHolder = this.cardHolder

    //   const secureData = {}
    //   secureData.authData = authData
    //   secureData.cardData = cardData

    //   window.Anet.dispatchData(secureData, (response) => {
    //     if (response.messages.resultCode === 'Error') {
    //       for (let index = 0; index < response.messages.message.length; index++) {
    //         this.snackbar = true
    //         this.text = 'error :' + response.messages.message[index].code + ': ' + response.messages.message[index].text
    //         this.color = 'red'
    //       }
    //     } else {
    //       this.authorizeNetToken = response.opaqueData
    //       this.book()
    //     }
    //   }).catch(err => {
    //     console.log('err', err)
    //   })
    // },
    book () {
      if (this.termsIsRead) {
        this.showOverlay = true
        const body = {
          // kountSessionId: sessionStorage.getItem('kountSessionId'),
          // sessionId: this.searchTerms.sessionId,
          // holdingRefernce: this.holdingRefernce,
          makkahHotel: {
            sessionId: this.meccaRoomsParams.sessionId,
            holdingRefernce: this.meccaHoldingRefernce
          },
          madinahHotel: {
            sessionId: this.medinaRoomsParams.sessionId,
            holdingRefernce: this.medinaHoldingRefernce
          },
          cash: true
        }
        // if (this.authorizeNetToken) {
        //   body.creditCardInfo = {
        //     cardHolderName: this.cardHolder,
        //     // descriptor: this.authorizeNetToken.dataDescriptor,
        //     // token: this.authorizeNetToken.dataValue,
        //     postalCode: this.$store.state.zipCode,
        //     zipCode: this.$store.state.zipCode,
        //     card_type: 'customer_card',
        //     address: this.$store.state.writtenAddress,
        //     city: this.$store.state.writtenCity,
        //     stateProvinceCode: this.$store.state.writtenState,
        //     country: this.$store.state.writtenCountry
        //   }
        // }
        hotels.bookHotels(body).then(res => {
          this.hotelsBookingDetails = res.data.data
          this.bookFlight()
        }).catch(() => {
          this.snackbar = true
          this.color = 'warning'
          this.text = 'Something went wrong while booking hotels'
          this.showOverlay = false
        })
      } else {
        this.snackbar = true
        this.color = 'warning'
        this.text = 'Please read and agree on our terms and conditions!'
      }
    },
    bookFlight () {
      const body = {
        flight_id: this.flight.id,
        search_id: this.search_id,
        contact_person_name: this.name,
        contact_phone: this.phone.nationalNumber,
        countryIsoCode: this.phone.countryCode,
        contact_email: this.email,
        passengerDetails: this.travellersData,
        zipCode: this.$store.state.zipCode, // this.zip,
        address: this.$store.state.writtenAddress, // this.address,
        streetLine: this.streetLine,
        city: this.$store.state.writtenCity, // this.city,
        state: this.$store.state.writtenState, // this.state,
        countryISO: this.$store.state.writtenCountry, // this.countryISO,
        creditCardNumber: this.cardNum,
        creditCardExpireDate: this.cardExpireDate,
        creditCardType: this.cardCode,
        cvv: this.cvv,
        cardHolderName: this.cardHolder,
        // kountSessionId: sessionStorage.getItem('kountSessionId'),
        hotels: {
          mecca: {
            name: this.meccaRoomsParams.hotel.HotelName,
            price: this.meccaHotelPrice,
            roomsNumber: this.meccaRoomsParams.rooms.length,
            bookingReference: this.hotelsBookingDetails.MakkahBooking.BookingNumber
          }
        }
      }
      if (this.medinaNights > 0) {
        body.hotels.medina = {
          name: this.medinaRoomsParams.hotel.HotelName,
          price: this.medinaHotelPrice,
          roomsNumber: this.medinaRoomsParams.rooms.length,
          bookingReference: this.hotelsBookingDetails.MadinahBooking.BookingNumber
        }
      }
      packages.savePackage(body).then(res => {
        if (res.data.status) {
          this.confirmData = res.data.data
          this.el = 3
          window.scrollTo(0, 0)
        } else {
          this.snackbar = true
          this.goNext = false
          this.color = 'error'
          this.showOverlay = false
          this.text = res.data.message
        }
      }).catch(() => {
        this.snackbar = true
        this.goNext = false
        this.color = 'error'
        this.disbleBookingBtn = true
        this.text = 'Something went wrong while booking the flight. Your session will be refreshed.'
        setTimeout(() => {
          this.$router.push('/')
        }, 3000)
      }).finally(() => {
        this.showOverlay = false
      })
    },
    holdRooms (params, city) {
      const body = {
        phoneNumber: this.phone.nationalNumber,
        countryIsoCode: this.phone.countryCode,
        phoneCountryCode: this.phone.countryCallingCode,
        contact_name_person: this.name,
        email: this.email,
        sessionId: params.sessionId,
        numberOfRooms: params.rooms.length,
        guests: []
      }
      this.rooms.forEach((room, n) => {
        room.adults.forEach((adult, i) => {
          if (i === 0) {
            body.guests.push({
              isLead: 1,
              firstName: adult.passengerFirstName,
              lastName: adult.passengerLastName,
              guestType: 'Adult',
              InRoom: n + 1,
              smoking: false
            })
          }
        })
      })
      hotels.holdHoetel(body).then(response => {
        if (response.data.data.roomStatus === 'soldOut') {
          if (city === 'mecca') this.meccaHotelHeld = false
          else this.medinaHotelHeld = false
        } else {
          if (response.data.data.roomStatus === 'priceChanged') {
            if (city === 'mecca') {
              this.meccaHoldingRefernce = response.data.data.holdingRefernce
              this.meccaHotelHeld = true
              this.packageTotalPrice -= Number(this.meccaHotelPrice)
              this.packageTotalPrice += Number(response.data.data.newPrice)
            } else {
              this.medinaHoldingRefernce = response.data.data.holdingRefernce
              this.medinaHotelHeld = true
              this.packageTotalPrice -= Number(this.medinaHotelPrice)
              this.packageTotalPrice += Number(response.data.data.newPrice)
            }
          } else {
            if (city === 'mecca') {
              this.meccaHoldingRefernce = response.data.data.holdingRefernce
              this.meccaHotelHeld = true
            } else {
              this.medinaHoldingRefernce = response.data.data.holdingRefernce
              this.medinaHotelHeld = true
            }
          }
        }
      }).catch((e) => {
        if (city === 'mecca') this.meccaHotelHeld = false
        else this.medinaHotelHeld = false
      })
    },
    setGuestInfo (guest, type) {
      this.travellersData.push({
        passengerType: type,
        passengerGender: guest.passengerGender === 'Male' ? 'M' : 'F',
        passengerFirstName: guest.passengerFirstName,
        passengerLastName: guest.passengerLastName,
        date_of_birth: this.getBirthdate(guest.date_of_birth),
        passport_number: guest.passport_number ? guest.passport_number : '',
        passport_expire_date: guest.passport_expire_date ? guest.passport_expire_date : '',
        passport_issue_country: guest.passport_issue_country ? guest.passport_issue_country : ''
      })
    },
    prepareTravellersData () {
      this.travellersData = []
      this.rooms.forEach(room => {
        room.adults.forEach(adult => {
          this.setGuestInfo(adult, 'ADT')
        })
        if (room.children.length) {
          room.children.forEach(child => {
            this.setGuestInfo(child, 'CNN')
          })
        }
        if (room.infants.length) {
          room.infants.forEach(infant => {
            this.setGuestInfo(infant, 'INF')
          })
        }
      })
      this.el = 2
    },
    getBirthdate (date) {
      if (date) {
        let newDate = ''
        const segments = date.replace(/\//g, '-').split('-')
        if (segments[0].length === 1) segments[0] = `0${segments[0]}`
        if (segments[1].length === 1) segments[1] = `0${segments[1]}`
        newDate = `${segments[0]}-${segments[1]}-${segments[2]}`
        return newDate
      }
    },
    nextClicked (currentPage) {
      try {
        if (currentPage === 1) {
          this.currentPage = 1
          this.$refs.form1.validate()
          if (this.valid1) {
            const fullNames = []
            for (let index = 0; index < this.passengerFirstName.length; index++) {
              fullNames.push(`${this.passengerFirstName[index]} ${this.passengerLastName[index]}`)
            }
            if (new Set(fullNames).size === fullNames.length) {
              if (new Set(this.passNum).size === this.passNum.length || this.flight.isDomesticFlight) {
                if (this.phone.valid) {
                  let passportExpireDateExists = true
                  for (let index = 0; index < this.passengerFirstName.length; index++) {
                    if (!this.passExpireDate[index]) passportExpireDateExists = false
                  }
                  if (passportExpireDateExists || this.flight.isDomesticFlight) {
                    let passportIssueCountryExists = true
                    for (let index = 0; index < this.passengerFirstName.length; index++) {
                      if (!this.issueCountry[index]) passportIssueCountryExists = false
                    }
                    if (passportIssueCountryExists || this.flight.isDomesticFlight) {
                      let birthDaysExists = true
                      for (let index = 0; index < this.passengerFirstName.length; index++) {
                        if (!this.dateOfBirth[index]) {
                          document.getElementsByClassName('mx-input').forEach((element, i) => {
                            if (!element.value) {
                              this.changeInputColor('birthDateId' + (i + 1), 'red')
                            }
                          })
                          birthDaysExists = false
                        }
                      }
                      if (birthDaysExists) {
                        let adultsOlderThan18 = 0
                        for (let i = 0; i < this.adults; i++) {
                          const now = new Date().getTime()
                          const birthDateInTime = new Date(this.getBirthdate(this.dateOfBirth[i])).getTime()
                          const years = new Date(now - birthDateInTime).getFullYear()
                          const age = years - 1970
                          // const age = new Date(Date.now() - new Date(this.getBirthdate(this.dateOfBirth[i]).getTime()).getFullYear() - 1970)
                          if (age >= 18) adultsOlderThan18++
                        }
                        if (adultsOlderThan18 >= this.infants) {
                          this.snackbar = false
                          // this.nextLabel = 'Book'
                          // this.el = 2
                          // window.scrollTo(0, 0)
                          this.holdRooms(this.meccaRoomsParams, 'mecca')
                          if (this.medinaNights > 0) this.holdRooms(this.medinaRoomsParams, 'medina')
                          this.prepareTravellersData()
                          // this.sendEmail()
                        } else {
                          this.snackbar = true
                          this.color = 'error'
                          this.text = 'Please note that each infant must be assigned to to an 18 years old or older person!'
                        }
                      } else {
                        this.snackbar = true
                        this.color = 'error'
                        this.text = 'Please make sure to enter pasengers birth dates.'
                      }
                    } else {
                      this.snackbar = true
                      this.color = 'error'
                      this.text = 'Please make sure to enter passports issue country.'
                    }
                  } else {
                    this.snackbar = true
                    this.color = 'error'
                    this.text = 'Please make sure to enter passports expiry dates.'
                  }
                } else {
                  this.snackbar = true
                  this.color = 'error'
                  this.text = 'Please provide a valid phone number'
                }
              } else {
                this.snackbar = true
                this.color = 'warning'
                this.text = 'Passports numbers must be unique'
              }
            } else {
              this.snackbar = true
              this.color = 'warning'
              this.text = 'Please avoid using similar names for passengers'
            }
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please fill all fields to continue'
          }
        } else if (currentPage === 2) {
          this.currentPage = 2
          this.$refs.form2.validate()
          if (this.valid2) {
            if ((this.cardExpireDate && this.paymentType === 'credit') || this.paymentType !== 'credit') {
              if (this.$store.state.writtenAddress) {
                if (this.$store.state.zipCode) {
                  // this.sendPaymentDataToAnet()
                  // this.sendEmail()
                  this.book()
                } else {
                  this.snackbar = true
                  this.color = 'error'
                  this.text = 'Please Enter your ZIP code'
                }
              } else {
                this.snackbar = true
                this.color = 'error'
                this.text = 'Please Enter your address'
                document.getElementsByClassName('address-autocomplete')[0].style.borderColor = 'red'
              }
            } else {
              this.snackbar = true
              this.color = 'error'
              this.text = 'Please Enter credit card expire date'
              document.getElementsByClassName('mx-auto')[0].style.borderColor = 'red'
            }
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please fill all fields to Book'
          }
        } else if (currentPage === 3) {
          this.currentPage = 3
          window.scrollTo(0, 0)
          this.$router.push({ name: 'Home' })
        }
      } catch (error) {}
    },
    backClicked (currentPage) {
      this.currentPage = currentPage
      if (currentPage === 3) {
        return false
      } else if (currentPage === 2) {
        window.scrollTo(0, 0)
        this.nextLabel = 'Continue'
        return true
      } else return true
    },

    // send email to agent with package details
    sendEmail () {
      const body = {
        flightId: this.flight.id,
        flightSearchId: this.search_id,
        phoneNumber: this.phone.nationalNumber,
        countryIsoCode: this.phone.countryCode,
        phoneCountryCode: this.phone.countryCallingCode,
        contact_name_person: this.name,
        email: this.email,
        zipCode: this.$store.state.zipCode, // this.zip,
        address: this.$store.state.writtenAddress, // this.address,
        streetLine: this.streetLine,
        city: this.$store.state.writtenCity, // this.city,
        state: this.$store.state.writtenState, // this.state,
        countryISO: this.$store.state.writtenCountry, // this.countryISO,
        creditCardNumber: this.cardNum,
        creditCardExpireDate: this.cardExpireDate,
        creditCardType: this.cardCode,
        cvv: this.cvv,
        cardHolderName: this.cardHolder,
        passengerDetails: this.travellersData,
        meccaHotel: this.meccaRoomsParams,
        madinaHotel: this.medinaRoomsParams
      }
      this.sendEmailLoad = true
      packages.sendToAgent(body).then(res => {
        if (res.data.status) {
          this.$router.push('/package/confirm')
        } else {
          this.snackbar = true
          this.color = 'error'
          this.text = res.data.message
        }
      }).catch(() => {
        this.snackbar = true
        this.color = 'error'
        this.text = 'Something went wrong while booking the flight. Your session will be refreshed.'
      }).finally(() => {
        this.sendEmailLoad = false
      })
    },

    getHotel (params, city) {
      hotels.checkHotelPrice(params).then(res => {
        if (res.data.data.roomStatus === 'soldOut') {
          this.soldOut = true
        } else {
          this.soldOut = false
          if (res.data.data.priceChanged) {
            if (city === 'mecca') {
              this.meccaHotelPrice = res.data.data.rateSummary.totalFare
              this.packageTotalPrice += Number(this.meccaHotelPrice)
            } else {
              this.medinaHotelPrice = res.data.data.rateSummary.totalFare
              this.packageTotalPrice += Number(this.medinaHotelPrice)
            }
          } else {
            if (city === 'mecca') {
              this.meccaHotelPrice = params.rooms[0].rateSummary.totalFare
              this.packageTotalPrice += Number(this.meccaHotelPrice)
            } else {
              this.medinaHotelPrice = params.rooms[0].rateSummary.totalFare
              this.packageTotalPrice += Number(this.medinaHotelPrice)
            }
          }
        }
      }).finally(() => {
        if (city === 'mecca') this.meccaHotelResolved = true
        else this.medinaHotelResolved = true
      })
    }
  },
  created () {
    window.scrollTo(0, 0)
    if (!this.$store.state.flight) {
      this.$router.go(-1)
    } else {
      this.search_id = this.$store.state.flight.search_id
      const params = new URLSearchParams()
      params.append('flight_id', this.$store.state.flight.id)
      params.append('search_id', this.search_id)
      this.loaded = true

      flights.getFlight(params).then(res => {
        if (res) {
          if (res.status === 200) {
            res.data.flight.data.search_id = this.search_id
            this.$store.dispatch('setFlight', res.data.flight.data)
            this.lastFlightSegmentDate = this.flight.flightSegments[this.flight.flightSegments.length - 1].leg.arrivalDate
            this.extraCost = res.data.extraCost
            const flightPriceWithFees = this.$options.filters.changeCurrency(Number(this.flight.pricingInfo.TotalFare) + Number(this.extraCost)).split(' ')[1]
            this.packageTotalPrice = Number(this.packageTotalPrice) + Number(flightPriceWithFees)
            this.flightAvailable = true
          } else {
            this.loaded = false
            this.snackbar = true
            this.color = 'error'
            this.text = 'Could not find flight!'
          }
        } else {
          this.loaded = false
        }
      })

      this.getHotel(this.meccaRoomsParams, 'mecca')
      if (this.medinaNights > 0) this.getHotel(this.medinaRoomsParams, 'medina')

      const travellerInfo = {
        passengerType: '',
        passengerGender: '',
        passengerFirstName: '',
        passengerLastName: '',
        date_of_birth: '',
        passport_number: '',
        passport_expire_date: '',
        passport_issue_country: '',
        seatSelectType: 'not selected',
        tsaRedress: '',
        frequentFlyer: [],
        passengerMeal: '',
        passengerSpecialService: '',
        passengerKnownNumber: ''
      }

      this.roomsAndoccupancy.forEach(room => {
        const roomGuests = {
          adults: [],
          children: [],
          infants: []
        }
        for (let index = 0; index < room.adults; index++) {
          const traveller = JSON.parse(JSON.stringify(travellerInfo))
          traveller.passengerType = 'ADT'
          roomGuests.adults.push(traveller)
        }
        for (let index = 0; index < room.children.length; index++) {
          if (room.children[index].age >= 2) {
            const traveller = JSON.parse(JSON.stringify(travellerInfo))
            traveller.passengerType = 'CNN'
            roomGuests.children.push(traveller)
          } else {
            const traveller = JSON.parse(JSON.stringify(travellerInfo))
            traveller.passengerType = 'INF'
            roomGuests.infants.push(traveller)
          }
        }
        this.rooms.push(roomGuests)
      })
    }
  }
}
</script>

<style>
  .mx-input {
    width: 100%;
    height: 56px !important;
    border-color: #9e9e9e;
  }
</style>
